import {BaseTranslationWithoutEnComponent} from "../../Generic/BaseTranslationComponent";
import {TrainingPlanTranslationFilter} from "../../../models/TrainingPlanTranslation";

export default class TrainingPlanTranslation extends BaseTranslationWithoutEnComponent {
    constructor(props) {
        super(props);
        this.name = "TrainingPlan Translations"
        this.addPath = "/admin/translations/training_plans/add";
        this.editPath = "/admin/translations/training_plans/edit";
        this.removeUrl = "/admin/translations/training_plans";
        this.listApiUrl = "/admin/translations/training_plans";
    }

    generateFilter = () => {
        return TrainingPlanTranslationFilter()
    }
}

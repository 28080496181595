const WorkoutFilter = {
    ids: {
        type: 'input',
        value: '',
    },
    tag: {
        type: 'input',
        value: '',
    }
};

export default WorkoutFilter;
import TagGroup from "controllers/TagGroup/TagGroup";
import Info from "./controllers/Info";
import TrainingPlan from "./controllers/TrainingPlan/TrainingPlan.jsx";
import TrainingPlanAdd from "./controllers/TrainingPlan/TrainingPlanAdd.jsx";
import TrainingPlanEdit from "./controllers/TrainingPlan/TrainingPlanEdit";
import TrainingPlanCluster from "./controllers/TrainingPlanCluster/TrainingPlanCluster";
import TrainingPlanClusterAdd from "./controllers/TrainingPlanCluster/TrainingPlanClusterAdd";
import TrainingPlanClusterEdit from "./controllers/TrainingPlanCluster/TrainingPlanClusterEdit";
import TrainingPlanTranslation from "./controllers/Translation/TrainingPlan/TrainingPlan";
import TrainingPlanTranslationAdd from "./controllers/Translation/TrainingPlan/TrainingPlanAdd";
import TrainingPlanTranslationEdit from "./controllers/Translation/TrainingPlan/TrainingPlanEdit";
import TrainingPlanCategoryTranslation from "./controllers/Translation/TrainingPlanCategory/TrainingPlanCategory";
import TrainingPlanCategoryTranslationAdd from "./controllers/Translation/TrainingPlanCategory/TrainingPlanCategoryAdd";
import TrainingPlanCategoryTranslationEdit from "./controllers/Translation/TrainingPlanCategory/TrainingPlanCategoryEdit";
import User from "./controllers/User/User";
import Workout from "./controllers/Workout/Workout.jsx";
import WorkoutAdd from "./controllers/Workout/WorkoutAdd";
import WorkoutEdit from "./controllers/Workout/WorkoutEdit";

import ContentCsvDownload from "controllers/ContentCsvDownload/ContentCsvDownload.jsx";
import TagGroupAdd from "controllers/TagGroup/TagGroupAdd";
import TagGroupEdit from "controllers/TagGroup/TagGroupEdit";
import Exercise from "./controllers/Exercises/Exercise";
import ExerciseAdd from "./controllers/Exercises/ExerciseAdd";
import ExerciseEdit from "./controllers/Exercises/ExerciseEdit";
import ExerciseTranslation from "./controllers/Translation/Exercise/Exercise";
import ExerciseTranslationAdd from "./controllers/Translation/Exercise/ExerciseAdd";
import ExerciseTranslationEdit from "./controllers/Translation/Exercise/ExerciseEdit";
import UniversalAudioTranslation from "./controllers/Translation/UniversalAudio/UniversalAudio";
import UniversalAudioTranslationAdd from "./controllers/Translation/UniversalAudio/UniversalAudioAdd";
import UniversalAudioTranslationEdit from "./controllers/Translation/UniversalAudio/UniversalAudioEdit";
import WorkoutTranslation from "./controllers/Translation/Workout/Workout";
import WorkoutTranslationAdd from "./controllers/Translation/Workout/WorkoutAdd";
import WorkoutTranslationEdit from "./controllers/Translation/Workout/WorkoutEdit";
import UniversalAudio from "./controllers/UniversalAudio/Audio/UniversalAudio";
import UniversalAudioAdd from "./controllers/UniversalAudio/Audio/UniversalAudioAdd";
import UniversalAudioEdit from "./controllers/UniversalAudio/Audio/UniversalAudioEdit";
import AudioCategory from "./controllers/UniversalAudio/Category/AudioCategory";
import AudioCategoryAdd from "./controllers/UniversalAudio/Category/AudioCategoryAdd";
import AudioCategoryEdit from "./controllers/UniversalAudio/Category/AudioCategoryEdit";
import WorkoutBlock from "./controllers/WorkoutBlock/WorkoutBlock";
import WorkoutBlockAdd from "./controllers/WorkoutBlock/WorkoutBlockAdd";
import WorkoutBlockEdit from "./controllers/WorkoutBlock/WorkoutBlockEdit";
import defaults from "./defaults";
let g = defaults.userGroups;

const dashboardRoutes = [
  // TrainingPlans
  {
    exact: true,
    path: "/training_plans",
    name: "Training Plans",
    icon: "pe-7s-check",
    component: TrainingPlan,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/training_plans/add",
    name: "Add Training Plan",
    component: TrainingPlanAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/training_plans/edit/:id",
    name: "Edit Training Plan",
    component: TrainingPlanEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // TrainingPlans Clusters
  {
    exact: true,
    submenu: true,
    path: "/training_plans/clusters",
    name: "Training Plan Clusters",
    component: TrainingPlanCluster,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/training_plans/clusters/add",
    name: "Add Training Plan Cluster",
    component: TrainingPlanClusterAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    submenu: true,
    path: "/training_plans/clusters/edit/:id",
    name: "Edit Training Plan Cluster",
    component: TrainingPlanClusterEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // Workouts
  {
    exact: true,
    path: "/workouts",
    name: "Workout",
    icon: "pe-7s-gym",
    component: Workout,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/workouts/add",
    name: "Workout Add",
    component: WorkoutAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/workouts/edit/:id",
    name: "Workout Edit",
    component: WorkoutEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },

  {
    exact: true,
    path: "/exercises",
    name: "Exercise",
    icon: "pe-7s-config",
    component: Exercise,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/exercise/add",
    name: "Exercise Add",
    icon: "pe-7s-config",
    component: ExerciseAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/exercise/edit/:id",
    name: "Exercise Edit",
    icon: "pe-7s-config",
    component: ExerciseEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    exact: true,
    path: "/workout-blocks",
    name: "Workout block",
    icon: "pe-7s-albums",
    component: WorkoutBlock,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/workout-blocks/add",
    name: "Workout Block Add",
    icon: "pe-7s-albums",
    component: WorkoutBlockAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/workout-blocks/edit/:id",
    name: "Workout Block Edit",
    icon: "pe-7s-albums",
    component: WorkoutBlockEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },

  // Universal Audio Categories
  {
    exact: true,
    path: "/universal-audio/categories",
    name: "Universal Audio",
    icon: "pe-7s-music",
    component: AudioCategory,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    exact: true,
    submenu: true,
    path: "/universal-audio/categories",
    name: "Audio Categories",
    component: AudioCategory,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/universal-audio/categories/add",
    name: "",
    component: AudioCategoryAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/universal-audio/categories/edit/:id",
    name: "",
    component: AudioCategoryEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // Universal Audio
  {
    exact: true,
    submenu: true,
    path: "/universal-audio",
    name: "Audio",
    component: UniversalAudio,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/universal-audio/add",
    name: "",
    component: UniversalAudioAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/universal-audio/edit/:id",
    name: "",
    component: UniversalAudioEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // Translations TrainingPlans
  {
    exact: true,
    path: "/translations/training_plans",
    name: "Translations",
    icon: "pe-7s-pen",
    component: TrainingPlanTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    exact: true,
    submenu: true,
    path: "/translations/training_plans",
    name: "Training Plans",
    component: TrainingPlanTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/translations/training_plans/add/:obj_id/:language",
    name: "",
    component: TrainingPlanTranslationAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/translations/training_plans/edit/:id",
    name: "",
    component: TrainingPlanTranslationEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // TrainingPlans Categories Translations
  {
    exact: true,
    submenu: true,
    path: "/translations/training_plans/categories",
    name: "Training Plan Categories",
    component: TrainingPlanCategoryTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/translations/training_plans/categories/add",
    name: "",
    component: TrainingPlanCategoryTranslationAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/translations/training_plans/categories/edit/:id",
    name: "",
    component: TrainingPlanCategoryTranslationEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // Translations exercises
  {
    exact: true,
    submenu: true,
    path: "/translations/exercises",
    name: "Exercises",
    component: ExerciseTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/translations/exercises/add",
    name: "",
    component: ExerciseTranslationAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/translations/exercises/edit/:id",
    name: "",
    component: ExerciseTranslationEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Translations Universal Audio
  {
    exact: true,
    submenu: true,
    path: "/translations/universal-audio",
    name: "Universal Audio",
    component: UniversalAudioTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/translations/universal-audio/add/:universal_audio_track_id/:language",
    name: "",
    component: UniversalAudioTranslationAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/translations/universal-audio/edit/:id",
    name: "",
    component: UniversalAudioTranslationEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Translations Workouts
  {
    exact: true,
    submenu: true,
    path: "/translations/workouts",
    name: "Workouts",
    component: WorkoutTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/translations/workouts/add/:obj_id/:language",
    name: "",
    component: WorkoutTranslationAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/translations/workouts/edit/:id",
    name: "",
    component: WorkoutTranslationEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },

  // Users
  {
    path: "/users",
    name: "Users",
    icon: "pe-7s-user",
    component: User,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer, g.support],
  },
  {
    path: "/info",
    name: "Info",
    icon: "pe-7s-info",
    component: Info,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer, g.support],
  },
  {
    path: "/content",
    name: "Content",
    icon: "pe-7s-info",
    component: ContentCsvDownload,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },

  // Tag groups
  {
    exact: true,
    path: "/tag_groups",
    name: "Tag Groups",
    icon: "pe-7s-ticket",
    component: TagGroup,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/tag_groups/add",
    name: "Tag Group Add",
    icon: "pe-7s-ticket",
    component: TagGroupAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/tag_groups/edit/:id",
    name: "Tag Group Edit",
    icon: "pe-7s-ticket",
    component: TagGroupEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
];

export default dashboardRoutes;

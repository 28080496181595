import CustomComponent from "components/CustomComponent/CustomComponent";
import React from "react";
import List from "../../components/List/List";
import { WorkoutBlockFilter } from "../../models/WorkoutBlock";
import { constToSelectOptions } from "../../utils/Utils";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";

export default class WorkoutBlock extends CustomComponent {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/workout-blocks/add";
    this.editPath = "/admin/workout-blocks/edit";
    this.name = "Workout Block";
    this.bulkUploadTurnedOn = true;
    this.bulkUploadFileType = "workout_block";
    this.uploadDocLink =
      "https://welltech.atlassian.net/wiki/spaces/WAL/pages/4451860540/Blocks+Create";
  }

  render() {
    let constants = this.context.constants;
    let workoutBlockTypes = constToSelectOptions(
      constants,
      "workout_block_type"
    );
    return (
      <List
        name="Workout Block"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/workout-blocks"
        listApiUrl="/admin/workout-blocks"
        model={[
          { col: "Id", row: "id" },
          { col: "Internal name", row: "internal_name" },
          {
            col: "Type",
            row: "block_type",
            normalizer: (data) => {
              return workoutBlockTypes[data.block_type];
            },
          },
          {
            col: "Exercises",
            row: "exercises",
            normalizer: (data) => {
              return data.exercises.map((a, i) => (
                <div key={i}>{`${i + 1}. ${a.name}`} </div>
              ));
            },
          },
          { col: "Time value", row: "exercise_time" },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        filterObject={WorkoutBlockFilter(constants)}
        userHasPermission={this.props.userHasPermission}
        headerChild={this.fileUploader()}
      ></List>
    );
  }
}

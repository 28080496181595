import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";

export default class TrainingPlanCategoryTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "TrainingPlan Category Translations"
        this.addPath = "/admin/translations/training_plans/categories/add";
        this.editPath = "/admin/translations/training_plans/categories/edit";
        this.removeUrl = "/admin/translations/training_plans/categories";
        this.listApiUrl = "/admin/translations/training_plans/categories";
    }
}

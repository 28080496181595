import React, {Component} from "react";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {
    TrainingPlanClusterFilter,
    TrainingPlanClusterKeys
} from "../../models/TrainingPlanCluster";


export default class TrainingPlanCluster extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/training_plans/clusters/add';
        this.editPath = '/admin/training_plans/clusters/edit';
    }

    render() {
        let constants = this.context.constants;
        let c = (key, item) => {
            if (item) {
                return constants[key]['items'][item]['title'];
            }
            return constants[key]['title'];
        };
        return (
            <List
                name="TrainingPlans Cluster"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/training_plans/clusters"
                listApiUrl='/admin/training_plans/clusters'
                model={[
                    {
                        col: 'Id', row: 'id'
                    },
                    {
                        col: 'User Clusters', row: 'id', normalizer: (data) => {
                            return Array.from(TrainingPlanClusterKeys, key => c(key, data[key])).join(' | ')
                        }
                    },
                    {
                        col: 'TrainingPlans',
                        row: 'training_plans',
                        normalizer: (data) => {
                            return data.training_plans.map((a, i) =>
                                <div key={i}>{`${i + 1}. [${a.id}] ${a.name}`} </div>)
                        }
                    },
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={TrainingPlanClusterFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}


import React, { Component } from "react";
import List from "../../components/List/List";
import WorkoutFilter from "../../models/WorkoutFilter";
import { constToSelectOptions } from "../../utils/Utils";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";

export default class Workout extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/workouts/add";
    this.editPath = "/admin/workouts/edit";
  }

  render() {
    let constants = this.context.constants;
    let workoutTypes = constToSelectOptions(constants, "workout_type");
    let workoutActivityType = constToSelectOptions(
      constants,
      "workout_activity_type"
    );

    return (
      <List
        name="Workout"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/workouts"
        listApiUrl="/admin/workouts"
        model={[
          { col: "Id", row: "id" },
          {
            col: "Image",
            row: "image_url",
            normalizer: (data) => {
              let imageValue = "-";
              if (data.image_url != null) {
                imageValue = (
                  <img
                    alt={data.name}
                    src={data.image_url || data.image_url}
                    width="150"
                  />
                );
              }
              return imageValue;
            },
          },
          { col: "Name", row: "name" },
          { col: "Tag", row: "tag" },
          {
            col: "Type",
            row: "type",
            normalizer: (data) => {
              return workoutTypes[data.type];
            },
          },
          {
            col: "Activity Type",
            row: "activity_type",
            normalizer: (data) => {
              return workoutActivityType[data.activity_type];
            },
          },

          {
            col: "Blocks v2",
            row: "blocks_v2",
            normalizer: (data) => {
              return data.blocks_v2.map((a, i) => (
                <div key={i}>{`${i + 1}. ${a.name}`} </div>
              ));
            },
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        filterObject={WorkoutFilter}
        userHasPermission={this.props.userHasPermission}
      ></List>
    );
  }
}

import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {ExerciseTranslationModel} from "../../../models/ExerciseTranslation";


export default class ExerciseTranslationAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Exercise Translation";
        this.url = "/admin/translations/exercises";
        this.skipPrepopulation = true;
    }

    locationState = () => {
        return this.props.location.state || { language: {}, translation: {} };
    };

    getTitle = () => {
        return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${
          this.locationState().language.title
        }`;
    };

    getEntity = () => {
        let state = this.locationState();
        let translation = state.translation || {};
        return ExerciseTranslationModel(
            state.resource || null,
            state.language.key || null,
            translation.name || null,
            translation.audio_name || null,
            translation.description || null,
            translation.audio_description || null,
          );
    }
}

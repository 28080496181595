export const UserFilter = {
    ids: {
        type: 'input',
        value: '',
        placeholder: 'Ids separated by comma'
    },
    email: {
        type: 'input',
        value: '',
    }
};

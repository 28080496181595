import React, {Component} from "react";
import List from "../../components/List/List";
import {UserFilter} from "../../models/User";

class User extends Component {
    constructor(props) {
        super(props);
        this.editPath = '/admin/users/edit';
    }

    render() {
        return (
            <List
                name="Users"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/client-users"
                listApiUrl='/admin/client-users'
                disableActions
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Email', row: 'email'},
                    {col: 'Name', row: 'name'},
                    {
                        col: 'Is Paid', row: 'is_paid', normalizer: (data) => {
                            return data.is_paid ? 'Yes' : 'No'
                        }
                    },
                    {col: 'Locale', row: 'locale'},
                    {col: 'Timezone', row: 'timezone'},
                    {col: 'Created At', row: 'created_at'},
                    {col: 'Client Version', row: 'client_version'},
                    {
                        col: 'Problem Zones',
                        row: 'problem_zones',
                        normalizer: (data) => {
                            return data.problem_zones ? data.problem_zones.join(', ') : '-'
                        }
                    },
                    {col: 'Fitness Level', row: 'fitness_level'},
                    {col: 'Goal', row: 'goal'},
                    {col: 'Weight', row: 'weight'},
                    {col: 'Height', row: 'height'},
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={UserFilter}
                userHasPermission={this.props.userHasPermission}
            >
            </List>
        );
    }
}

export default User;

import { API } from "aws-amplify";
import React from "react";
import { components } from "react-select";

const Option = ({ children, ...props }) => {
  let option = props.data;
  return (
    <components.Option {...props}>
      <div className="clearfix">
        <div className="mb-9 pull-left" style={{ marginLeft: "10px" }}>
          <p style={{ margin: "0" }}>
            <strong>{`Tag: ${option.name}`}</strong>
          </p>
        </div>
      </div>
    </components.Option>
  );
};

export const TagGroup = (tagsOptions, tagLevels) => {
  return {
    id: {
      type: "integer",
    },
    tags: {
      type: "table_collection",
      value: [],
      prototype: Tags(tagsOptions, tagLevels),
      onChangeEvent: "handleCollection",
      validationRules: "required",
      onDelete: function (event, idx) {
        console.log("this", this.state);
        return API.del(
          "admin",
          `/admin/feedback/tags/${this.value.id}/groups/${this.objId}`
        ).then((data) => {
          console.log(data);
          window.location.reload();
        });
      },
      requestNormalizer: function (data) {
        return data.map((elem) => elem.id);
      },
    },
  };
};

export const Tags = (tagsOptions, tagLevels) => {
  return {
    id: {
      type: "select",
      validationRules: "required",
      onChangeEvent: "handleSelect",
      value: "",
      selectOptions: tagsOptions,
      requestNormalizer: (option) => {
        return option.id;
      },
      responseNormalizer: (option) => {
        return option;
      },
      selectProps: {
        components: { Option },
        getOptionLabel: (option) => {
          return `[${option.id}] ${option.name}`;
        },
        getOptionValue: (option) => {
          return option.id;
        },
        pageSize: 10,
        minMenuHeight: 500,
        maxMenuHeight: 2000,
      },
      md: 4,
    },
    tag_level: {
      type: "select",
      validationRules: "required",
      onChangeEvent: "handleSelect",
      value: "",
      selectOptions: tagLevels,
      requestNormalizer: (option) => {
        return option.name;
      },
      responseNormalizer: (option) => {
        return option;
      },
      selectProps: {
        components: { Option },
        getOptionLabel: (option) => {
          return `${option.name}`;
        },
        getOptionValue: (option) => {
          return option.name;
        },
        pageSize: 10,
        minMenuHeight: 500,
        maxMenuHeight: 2000,
      },
      md: 4,
    },
  };
};

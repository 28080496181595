import { API } from "aws-amplify";
import { TrainingPlanModel } from "../../models/TrainingPlan";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";

export default class TrainingPlanAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.state = {
      workouts: {},
    };
    this.name = "TrainingPlan";
    this.url = "/admin/training_plans";
  }

  componentDidMount() {
    API.get("admin", "/admin/workouts", {
      queryStringParameters: { page: "1", per_page: "1000" },
    })
      .then((data) => {
        let workouts = data.items.reduce(function (obj, item) {
          obj[item.id] = `[${item.id}] ${item.name || "<no name>"}`;
          return obj;
        }, {});
        this.setState({
          workouts: workouts,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getEntity = () => {
    return TrainingPlanModel(this.state.workouts, this.context.constants);
  };
}

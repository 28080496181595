import {buildSelector,constToSelectOptions} from "../utils/Utils";
import {TrainingPlanWorkout} from "./TrainingPlanWorkout";

export const TrainingPlanModel = (workouts, constants) => {
    return {
        id: {
            value: null,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        system_name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        male_preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        female_preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        male_preview_v2: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        female_preview_v2: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        description: {
            type: 'input',
            // validationRules: 'required',
            value: '',
            selectProps: {
                rows: 7,
                componentClass: "textarea",
                as: "textarea",
            },
        },
        category: buildSelector(constToSelectOptions(constants, 'training_plan_category'), 'select'),
        activity_types: buildSelector(constToSelectOptions(
            constants,
            'activity_type'),
            'multiSelect'
        ),
        workouts: {
            type: 'collection',
            value: [],
            prototype: TrainingPlanWorkout(workouts),
            onChangeEvent: 'handleCollection',
            validationRules: 'required',
            requestNormalizer: function (data) {
                return data.map(elem => parseInt(elem.id));
            },
        },
    };
}

export const TrainingPlanFilter = (categories) => {

    return {

        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            }
        },

        name: {
            type: 'input',
            inputType: 'text',
            value: '',
        },

        system_name: {
            type: 'input',
            value: '',
            placeholder: 'System name'
        },

        category: buildSelector(
            categories,
            'select'
            )

    }
}
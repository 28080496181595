import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {TrainingPlanCategoryTranslationModel} from "../../../models/TrainingPlanCategoryTranslation";


export default class TrainingPlanCategoryTranslationAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "TrainingPlan Category Translation"
        this.url = "/admin/translations/training_plans/categories"
    }

    locationState = () => {
        return this.props.location.state || {language: {}}
    }

    getTitle = () => {
        return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${this.locationState().language.title}`
    }

    getEntity = () => {
        let state = this.locationState()
        return TrainingPlanCategoryTranslationModel(
            state.resource || null,
            state.language.key || null
        );
    }
}

import React, { Component } from "react";
import List from "../../components/List/List";
import { TrainingPlanFilter } from "../../models/TrainingPlan";
import { constToSelectOptions } from "../../utils/Utils";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";

export default class TrainingPlan extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/training_plans/add";
    this.editPath = "/admin/training_plans/edit";
  }

  render() {
    let constants = this.context.constants;
    let activityTypes = constToSelectOptions(constants, "activity_type");
    let categories = constToSelectOptions(constants, "training_plan_category");
    return (
      <List
        name="TrainingPlan"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/training_plans"
        listApiUrl="/admin/training_plans"
        model={[
          { col: "Id", row: "id" },
          {
            col: "Female preview",
            row: "preview",
            normalizer: (data) => {
              return (
                <img
                  alt={data.name}
                  src={data.female_preview_v2 || data.female_preview}
                  width="150"
                />
              );
            },
          },
          { col: "Name", row: "name" },
          { col: "System name", row: "system_name" },
          {
            col: "Category",
            row: "category",
            normalizer: (data) => {
              return categories[data.category];
            },
          },
          { col: "Workouts", row: "workout_count" },
          { col: "Plan clusters", row: "cluster_count" },
          {
            col: "Activity types",
            row: "activity_types",
            normalizer: (data) => {
              return (
                <ul>
                  {data.activity_types.map((key, index) => {
                    return <li key={index}>{activityTypes[key]}</li>;
                  })}
                </ul>
              );
            },
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        filterObject={TrainingPlanFilter(categories)}
        userHasPermission={this.props.userHasPermission}
      ></List>
    );
  }
}

import { API, Auth } from "aws-amplify";
import Card from "components/Card/Card";
import FilterForm from "components/Form/FilterForm";
import React, { Component } from "react";
import { buildSelector } from "utils/Utils";

export default class ContentCsvDownload extends Component {
  constructor(props) {
    super(props);
    this.contentTables = [
      "exercise",
      "exercise_translation",
      "workout_block_exercise",
      "training_plan_workouts",
      "workout_blocks",
    ];
  }
  handleSubmit(data) {
    let requestData = {
      content_type: data.content,
    };
    Auth.currentSession().then((res) => {
      requestData.email = res.getIdToken().decodePayload().email;
      API.post("admin", "/admin/content", { body: requestData });
    });
  }
  render() {
    let contentTables = new Object();
    this.contentTables.forEach((itm) => {
      contentTables[itm] = itm.replace("_", " ");
    });
    contentTables = buildSelector(contentTables, "select");
    return (
      <Card
        title={"Generate csv for content tables"}
        content={
          <FilterForm
            object={{ content: contentTables }}
            onSubmit={this.handleSubmit}
            submitBtnText="Send Email"
          />
        }
      />
    );
  }
}

import Card from "components/Card/Card";
import React, { Component } from "react";
import { Button } from "react-bootstrap";

export default class FileUpload extends Component {
  render() {
    let updateButton;
    if (this.props.updateTurnedOn === true) {
      updateButton = (
        <Button
          onClick={() => {
            this.props.fileUploadHandler(true);
          }}
          bsStyle="warning"
        >
          Upload for Update
        </Button>
      );
    }
    return (
      <Card
        title={"Upload " + this.props.name}
        content={
          <div className="clearfix">
            <div
              className="float-container"
              style={{
                height: 100,
                float: "left",
                justifyContent: "space-between",
              }}
            >
              <input
                type="file"
                accept={this.props.uploadAcceptType}
                onChange={this.props.fileSelectedHandler}
                className="form-group"
              />
              <Button
                onClick={() => {
                  this.props.fileUploadHandler(false);
                }}
                bsStyle="warning"
              >
                Upload New
              </Button>
              {updateButton}
              <a href={this.props.uploadDocLink} target="_blank">
                <Button bsStyle="info">Documents</Button>
              </a>
            </div>
          </div>
        }
      />
    );
  }
}

import CustomComponent from "components/CustomComponent/CustomComponent";
import React from "react";
import List from "../../components/List/List";
import { ExerciseFilter } from "../../models/Exercise";
import { constToSelectOptions } from "../../utils/Utils";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";

export default class Exercise extends CustomComponent {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/exercise/add";
    this.editPath = "/admin/exercise/edit";
    this.bulkUploadTurnedOn = true;
    this.updateTurnedOn = true;
    this.bulkUploadFileType = "exercise";
    this.name = "Exercise";
    this.uploadDocLink =
      "https://welltech.atlassian.net/wiki/spaces/WAL/pages/4451860528/Exercises+Create+Update";
  }

  render() {
    let constants = this.context.constants;
    let exerciseTypes = constToSelectOptions(constants, "exercise_type");
    return (
      <List
        name="Exercises"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/exercises"
        listApiUrl="/admin/exercises"
        model={[
          { col: "Id", row: "id" },
          {
            col: "Preview",
            row: "preview",
            normalizer: (data) => {
              return <img alt={data.name} src={data.preview} width="150" />;
            },
          },
          { col: "Name", row: "name" },
          {
            col: "Type",
            row: "type",
            normalizer: (data) => {
              return exerciseTypes[data.type];
            },
          },
          { col: "Base Pace", row: "base_pace" },
          { col: "Mets", row: "mets" },
          {
            col: "Description",
            row: "description",
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        filterObject={ExerciseFilter(constants)}
        userHasPermission={this.props.userHasPermission}
        headerChild={this.fileUploader()}
      ></List>
    );
  }
}

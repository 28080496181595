import { BaseIdAndNameFilter } from "../../../models/Base";
import { BaseTranslationWithoutEnComponent } from "../../Generic/BaseTranslationComponent";

export default class WorkoutTranslation extends BaseTranslationWithoutEnComponent {
  constructor(props) {
    super(props);
    this.name = "Workout Translations";
    this.addPath = "/admin/translations/workouts/add";
    this.editPath = "/admin/translations/workouts/edit";
    this.removeUrl = "/admin/translations/workouts";
    this.listApiUrl = "/admin/translations/workouts";
    this.bulkUploadTurnedOn = true;
    this.updateTurnedOn = true;
    this.bulkUploadFileType = "workout_translation";
    this.uploadDocLink =
      "https://welltech.atlassian.net/wiki/spaces/WAL/pages/4451827752/Localizations+Workouts+Create";
  }

  generateFilter = () => {
    return BaseIdAndNameFilter();
  };
}

import { ExerciseTranslationFilter } from "../../../models/ExerciseTranslation";
import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";

export default class ExerciseTranslation extends BaseTranslationComponent {
  constructor(props) {
    super(props);
    this.name = "Exercise Translations";
    this.addPath = "/admin/translations/exercises/add";
    this.editPath = "/admin/translations/exercises/edit";
    this.removeUrl = "/admin/translations/exercises";
    this.listApiUrl = "/admin/translations/exercises";
    this.bulkUploadTurnedOn = true;
    this.updateTurnedOn = true;
    this.bulkUploadFileType = "exercise_translation";
    this.uploadDocLink =
      "https://welltech.atlassian.net/wiki/spaces/WAL/pages/4432560291/Localisation+exercises";
  }

  generateFilter = () => {
    return ExerciseTranslationFilter();
  };
}

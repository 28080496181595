export const TrainingPlanTranslationModel = (training_plan_id, language_id) => {
    return {
        training_plan_id: {
            value: training_plan_id,
            hidden: true
        },
        language: {
            value: language_id,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        description: {
            type: 'input',
            value: null,
            // validationRules: 'required',
            selectProps: {
                rows: 7,
                componentClass: "textarea",
                as: "textarea",
            },
        },
    };
}

export const TrainingPlanTranslationFilter = () => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids separated by comma',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        }
    };
}
import React, {Component} from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import FormRow from "./FormRow";
import {Draggable} from "react-beautiful-dnd";

class CollectionRow extends Component {
    constructor(props) {
        super(props);
        this.object = this.props.object;
        this.state = this.props.value;
    }

    componentWillReceiveProps(props) {
        // Fixme https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-controlled-component
        this.setState(props.value)
        this.object = props.object;
    }

    handleInput = event => {
        this.setState({[event.target.name]: event.target.value}, () => {
            this.props.handleChange(this.state, this.props.index)
        });
    };
    handleSelect = (value, event) => {
        this.setState({[event.name]: value}, () => {
            this.props.handleChange(this.state, this.props.index)
        });
    };

    render() {
        return <Draggable key={this.props.index} draggableId={'item-'+this.props.index} index={this.props.index}>
            {(provided, snapshot) => (
                <li ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    {Object.keys(this.object).map((key, index) => {
                        if (!this.object[key].hidden) {
                            return <FormRow
                                key={index}
                                name={key}
                                type={this.object[key].type || 'input'}
                                inputType={this.object[key].inputType}
                                value={this.state[key] || ''}
                                validationRules={this.object[key].validationRules || ''}
                                onChangeEvent={this[this.object[key].onChangeEvent] || this.handleInput}
                                selectOptions={this.object[key].selectOptions || null}
                                validator={this.props.validator}
                                label={false}
                            />
                        }

                        return null
                    })}
                    <Button bsStyle="danger" onClick={(e) => this.props.onDelete(e, this.props.index)}>
                        Delete
                    </Button>
                    <span className="label label-default pull-right">#{this.props.index + 1}</span>
                </li>
            )}
        </Draggable>
    }
}

export default CollectionRow;

import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {TrainingPlanTranslationModel} from "../../../models/TrainingPlanTranslation";


export default class TrainingPlanTranslationAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "TrainingPlan Translation"
        this.url = "/admin/translations/training_plans"
    }

    getTitle = () => {
        return this.isEdit() ? ((data) => {return `Edit ${this.name} for ${data.language}`}) :
            `Add ${this.name} for ${this.props.match.params.language}`
    }


    getEntity = () => {
        let language = this.props.match.params.language
        let obj_id = this.props.match.params.obj_id
        if (typeof obj_id !== undefined){
            obj_id = parseInt(obj_id)
        }

        return TrainingPlanTranslationModel(
            obj_id || null,
            language || null);
    }
}

import { API } from "aws-amplify";
import React from "react";
import { components } from "react-select";
import { buildSelector, constToSelectOptions } from "../utils/Utils";

const Option = ({ children, ...props }) => {
  let option = props.data;

  return (
    <components.Option {...props}>
      <div className="clearfix">
        <div className="mb-3 pull-left">
          <img
            alt={"Preview"}
            className="pull-left mr-2 img-rounded"
            width="128"
            height="128"
            src={option.preview}
          />
        </div>
        <div className="mb-9 pull-left" style={{ marginLeft: "10px" }}>
          <p style={{ margin: "0" }}>
            <strong>{`[${option.id}] ${option.name}`}</strong>
          </p>
          <p style={{ margin: "0" }}>{`Mets: ${option.mets}`}</p>
          <p style={{ margin: "0" }}>{`Base Pase: ${option.base_pace}`}</p>
          <p style={{ margin: "0" }}>{`Type: ${option.type}`}</p>
        </div>
      </div>
    </components.Option>
  );
};

export const BlockExerciseModel = (constants, loadOptions) => {
  let objCache = {};
  return {
    id: {
      value: null,
      hidden: true,
    },
    exercise_id: {
      type: "select",
      validationRules: "required",
      onChangeEvent: "handleSelect",
      value: "",
      requestNormalizer: (option) => {
        return option.id;
      },
      responseNormalizer: (option) => {
        let exercise_id = option;
        if (objCache[exercise_id] !== undefined) {
          return objCache[exercise_id];
        }
        return API.get("admin", `/admin/exercises/${exercise_id}`)
          .then((data) => {
            objCache[exercise_id] = data;
            return data;
          })
          .catch((error) => {
            console.error(error);
            return {};
          });
      },
      selectProps: {
        components: { Option },
        getOptionLabel: (option) => {
          return `[${option["id"]}] ${option["name"]}`;
        },
        getOptionValue: (option) => {
          return option["id"];
        },
        pageSize: 10,
        minMenuHeight: 300,
        maxMenuHeight: 1000,
        loadOptions: loadOptions,
      },
      md: 4,
    },
    pace: {
      ...buildSelector(constToSelectOptions(constants, "pace"), "select"),
      value: null,
      validationRules: "required",
      md: 2,
    },
    value_type: {
      ...buildSelector(
        constToSelectOptions(constants, "block_exercise_value_type"),
        "select"
      ),
    },

    value: {
      inputType: "number",
      value: "",
      validationRules: ["required", { regex: "^[1-9]\\d*$" }],
      pattern: "d+",
      requestNormalizer: function (data) {
        return parseInt(data);
      },
      md: 1,
    },
    min_heart_rate: {
      inputType: "number",
      value: "",
      requestNormalizer: function (data) {
        return parseInt(data);
      },
      md: 1,
    },
    max_heart_rate: {
      inputType: "number",
      value: "",
      requestNormalizer: function (data) {
        return parseInt(data);
      },
      md: 1,
    },
    intro_duration: {
      inputType: "number",
      validationRules: ["required", { regex: "^[0-9]\\d*$" }],
      pattern: "d+",
      value: "",
      requestNormalizer: function (data) {
        return parseInt(data);
      },
      md: 1,
    },
  };
};

export const WorkoutBlock = (constants, loadOptions) => {
  return {
    internal_name: {
      type: "input",
      value: "",
      md: 5,
    },
    block_type: buildSelector(
      constToSelectOptions(constants, "workout_block_type"),
      "select",
      undefined,
      undefined,
      5
    ),
    exercises: {
      type: "table_collection",
      value: [],
      prototype: BlockExerciseModel(constants, loadOptions),
      onChangeEvent: "handleCollection",
      validationRules: "required",
    },
  };
};

export const WorkoutBlockFilter = (constants) => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
    internal_name: {
      type: "input",
      value: "",
    },
  };
};

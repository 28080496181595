import { API, Auth } from "aws-amplify";
import Card from "components/Card/Card";
import FilterForm from "components/Form/FilterForm";
import React, { Component } from "react";
import List from "../../components/List/List";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";

export default class TagGroup extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/tag_groups/add";
    this.editPath = "/admin/tag_groups/edit";
  }

  getAllQueryParams = () => {
    return Object.assign({}, {}, {});
  };

  handleReportSend = () => {
    let dateEnd = new Date();
    let dateStart = new Date();
    dateStart.setDate(dateEnd.getDate() - 30);
    dateEnd = dateEnd.toISOString().split("T")[0];
    dateStart = dateStart.toISOString().split("T")[0];
    let requestData = {
      date_after: dateStart,
      date_before: dateEnd,
      email: "",
    };
    Auth.currentSession()
      .then((res) => {
        requestData.email = res.getIdToken().decodePayload().email;
        API.post("admin", "/admin/feedback/tags/groups/report", {
          body: requestData,
        });
      })
      .then(alert("Report generating, check your email."));
  };

  tagBranchGroupReportForm = () => {
    return (
      <Card
        title={"Send email with tag-branch report for the last 30 days"}
        content={
          <FilterForm
            object={{ content: { hidden: true } }}
            onSubmit={this.handleReportSend}
            submitBtnText="Send Email"
          />
        }
      />
    );
  };

  render() {
    return (
      <List
        headerChild={this.tagBranchGroupReportForm()}
        withPagination={false}
        name="Tag Group"
        addPath={this.addPath}
        editPath={this.editPath}
        listApiUrl="/admin/feedback/tags/groups"
        model={[
          { col: "Id", row: "id" },
          {
            col: "Tags",
            row: "tags",
            normalizer: (data) => {
              return (
                <ul>
                  {data.tags.map((itm) => {
                    return (
                      <li key={itm.id}>
                        <b>{itm.name}</b>
                        &nbsp;|&nbsp;tag level: {itm.tag_level}
                      </li>
                    );
                  })}
                </ul>
              );
            },
          },
        ]}
        allowedDelete={[]}
        handleClick={this.props.handleClick}
        userHasPermission={this.props.userHasPermission}
      ></List>
    );
  }
}

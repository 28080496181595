import { API } from "aws-amplify";
import { TagGroup } from "models/Tag";
import React from "react";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";

export default class TagGroupAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Tag Group";
    this.url = "/admin/feedback/tags/groups";
    this.redirectUrl = "/admin/tag_groups";
    this.state = {
      tagsOptions: [],
      tagLevels: [
        { name: "category" },
        { name: "sub_category" },
        { name: "default" },
      ],
    };
  }

  componentDidMount() {
    this.loadTags().then((tagsOptions) => {
      this.setState({ tagsOptions });
    });
  }

  loadTags = () => {
    return API.get("admin", "/admin/feedback/tags", {})
      .then((data) => {
        let tags = data.items.reduce(function (obj, item) {
          obj[item.id] = item;
          return obj;
        }, {});
        return Object.values(tags);
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  };

  getEntity = () => {
    return TagGroup(this.state.tagsOptions, this.state.tagLevels);
  };

  render() {
    return React.cloneElement(super.render(), { md: 12 });
  }
}

import { API, Auth } from "aws-amplify";
import FileUpload from "components/FileUpload/FileUpload";
import React, { Component } from "react";
import { getPresignedUploadUrl, postCsvToS3 } from "utils/Utils";
export default class CustomComponent extends Component {
  constructor(props) {
    super(props);
    this.bulkUploadTurnedOn = false;
    this.bulkUploadFileType = "";
    this.uploadApiUrl = "/admin/uploads/bulk";
    this.uploadDocLink = "";
    this.updateTurnedOn = false;
  }

  fileSelectedHandler = (event) => {
    this.setState({ fileData: event.target.files[0] });
  };

  fileUploader = () => {
    if (this.bulkUploadTurnedOn) {
      return (
        <FileUpload
          name={this.name}
          uploadAcceptType=".csv"
          fileSelectedHandler={this.fileSelectedHandler.bind(this)}
          fileUploadHandler={this.fileUploadHandler.bind(this)}
          uploadDocLink={this.uploadDocLink}
          updateTurnedOn={this.updateTurnedOn}
        ></FileUpload>
      );
    }
  };

  fileUploadHandler = (forUpdate) => {
    if (this.state.fileData) {
      let requestData = {
        file_type: this.bulkUploadFileType,
        update: forUpdate,
      };
      Auth.currentSession()
        .then((res) => {
          requestData.email = res.getIdToken().decodePayload().email;
          return getPresignedUploadUrl(this.state.fileData.name);
        })
        .then((url_response_data) => {
          requestData.file_name = url_response_data.fields.key;

          return postCsvToS3(
            url_response_data.url,
            this.state.fileData,
            url_response_data.fields
          );
        })
        .then((_) => {
          API.post("admin", this.uploadApiUrl, { body: requestData }).then(
            (data) => {
              this.props.handleClick(data.message, "success", "tr");
            }
          );
        })
        .catch((error) => {
          this.props.handleClick(
            error.response.data.error || error.response.data.message,
            "error",
            "tr"
          );
        });
    }
  };
}

import {API} from "aws-amplify";
import {TrainingPlanClusterModel} from "../../models/TrainingPlanCluster";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";

export default class TrainingPlanClusterAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.state = {
            training_plans: {},
        }
        this.name = "TrainingPlan Cluster"
        this.url = "/admin/training_plans/clusters"
    }

    componentDidMount() {
        API.get(
          'admin',
          '/admin/training_plans',
          {'queryStringParameters': {"page": "1", "per_page": "1000"}})
            .then(data => {
                let training_plans = data.items.reduce(function (obj, item) {
                    obj[item.id] = `[${item.id}] ${item.name} (${item.category})`;
                    return obj;
                }, {});
                this.setState({
                    training_plans: training_plans
                })
            }).catch(error => {
            console.error(error)
        })
    }

    getEntity = () => {
        return TrainingPlanClusterModel(this.state.training_plans, this.context.constants);
    }
}
export const TrainingPlanCategoryTranslationModel = (category_id, language_id) => {
    return {
        category_id: {
            value: category_id,
            hidden: true
        },
        language_id: {
            value: language_id,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        }
    };
}